<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading><span class="red--text">浦</span>安北栄店</heading>
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >東西線浦安駅徒歩6分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県浦安市北栄2丁目1-32 LEO参拾四番館 1階</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日16:00～22:00 / 土日10:00～19:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >不定休</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1740625390331!6m8!1m7!1sHIcv7Jh3ARQaPm6SRiPCNA!2m2!1d35.66674642781192!2d139.8988698807369!3f290.9014242093732!4f-4.239151871105577!5f0.7820865974627469"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">浦安駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て左側（南口）のロータリーから西友側にいきます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            西友の前の道沿いをまっすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                信号を渡って、くすりの福太郎とイタリアンの間の道をまっすぐ進みます。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            自転車屋さんの交差点を左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            セブンイレブンの前の道もそのまままっすぐ進むとこの道沿いにあります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            産婦人科の交差点をまっすぐ渡ってすぐに左側の建物です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            こちらの建物がREVOIST浦安北栄店で、店舗入口は正面側ではなく脇道側です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">8</span>.
                            この脇道側にREVOIST浦安北栄店の入口があります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">9</span>.
                            こちらがREVOIST浦安北栄店です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessI"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
// import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの15号店です！
平井駅から徒歩6分、ゆりのき橋通り沿いにあるジムです！

誠実さのあるトレーナーが悩みをしっかりと聞き、その方の目標や身体の状態に合わせて、オーダーメイドでレッスンを構築していきます！
大切なのは「継続すること」です！
ひとりではなかなか運動が続かない方も多いと思いますが、トレーニングも食事も出来ることからコツコツと！
健康的な生活習慣を一緒に手に入れましょう！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        // ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '浦安北栄店',
            center: {
                lat: 35.66675666275916,
                lng: 139.898696536097,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.66675666275916,
                        lng: 139.898696536097,
                    },
                    title: '浦安北栄店',
                },
            ],
            message: `パーソナルジム REVOISTの15号店です！<br />
平井駅から徒歩6分、ゆりのき橋通り沿いにあるジムです！<br /><br />
誠実さのあるトレーナーが悩みをしっかりと聞き、その方の目標や身体の状態に合わせて、オーダーメイドでレッスンを構築していきます！<br />
大切なのは「継続すること」です！<br />
ひとりではなかなか運動が続かない方も多いと思いますが、トレーニングも食事も出来ることからコツコツと！<br />
健康的な生活習慣を一緒に手に入れましょう！<br /><br />
平井ゆりのき橋通り店トレーナー：酒谷 大輝
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '浦安北栄店',
                    disabled: true,
                    href: 'access-16',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture10_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture10_UrayasuKitaSakae.jpg'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture1_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture1_UrayasuKitaSakae.jpg'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture2_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture2_UrayasuKitaSakae.jpg'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture3_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture3_UrayasuKitaSakae.jpg'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture4_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture4_UrayasuKitaSakae.jpg'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture5_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture5_UrayasuKitaSakae.jpg'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture6_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture6_UrayasuKitaSakae.jpg'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture7_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture7_UrayasuKitaSakae.jpg'
        },
        accessH() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture8_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture8_UrayasuKitaSakae.jpg'
        },
        accessI() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture9_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture9_UrayasuKitaSakae.jpg'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture9_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture9_UrayasuKitaSakae.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_16/Picture11_UrayasuKitaSakae.jpg'
                : 'static/pc_access_16/Picture11_UrayasuKitaSakae.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
